<template>
 <div>
        <v-row dense class="mt-4">
            <v-col cols="12">
              <v-toolbar dense  dark color="primary">
            
            <span class="_headtext-md"><v-icon left>mdi-ticket-confirmation-outline</v-icon> TICKETS</span>
            <v-spacer />
            <!-- <v-icon color="success" @click="state='new',form=true">mdi-plus-circle</v-icon> -->
         </v-toolbar>
             <v-card tile>
              <v-toolbar dense flat class="pa-1">
                 <span style="width: 140px; margin-top: 24px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="Date"
                              :value="to_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, setData()" />
                    </v-menu>
               </span>
                 <v-spacer/>
                  <span style="width: 140px; "> 
                    <v-select
                        dense
                        v-model="game"
                        outlined
                        label="Select Game"
                        hide-details
                        :items="GAMESLIST"
                        return-object
                      />
                  </span>
                  
              </v-toolbar>
              <v-row>
                <v-col cols="12">
                  <span class="lg-font ma-1 text-primary"> TOTAL: {{$money(TOTAL)}}</span>
                </v-col>
                
              </v-row>
                  
                  <table class="va-table print_table3 mt-1 md-font text-left" cellspacing="0">
                     <tr>
                       <th  style="background: #eaeaea; padding: 4px">TICKET</th>
                       <th  style="background: #eaeaea; padding: 4px" class="text-right">AMOUNT</th>
                     </tr>
                      <tr v-for="(item, i) in ITEMS" :key="i">
                          <td @click="ticket = item, viewticket = true">
                            Game: {{item.game}}
                            <br/> Trans #: {{item.id}}
                            <br/> Trans DT: {{$moment(item.transaction_date).format('YYYY-MM-DD hh:mm A')}}
                            <br/> Draw: {{item.draw_date}}
                          </td>
                          <td class="text-right" @click="ticket = item, ticketviewticket = true">
                            {{$money(item.amount)}}
                              <template v-if="item.status=='win'">
                                  <br/><v-chip x-small color="green" dark>Win</v-chip>
                              </template>
                            </td>
                      </tr>
                  </table>
                  <v-alert
                  v-if="ITEMS.length == 0"
                  dense
                  type="info"
                  >
                  No tickets found!
                  </v-alert>
             </v-card>
            </v-col>
        </v-row>
         
<va-ticket :show="viewticket" :ticket="ticket" @DialogEvent="ticketEvent"/>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      ticket: {},
      to_menu: false,
      viewticket: false,
      to_date:'',
      tickets: [],
      confirm: false,
      cancelbtn: 'No',
      okbtn: "Ok",
      confirmText: "",
      action:'',
      game:'All Games'
    }),
    created() {
      this.PING()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.to_date = this.$moment().format("YYYY-MM-DD")
          this.setData()
        }
    },
    computed:{
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      TOTAL(){
        var total =  this.ITEMS.reduce((res, item)=>res += this.$IsNum(item.amount),0)
        return total
      },
      GAMESLIST() {
        var items =this.$store.state.games
        //items.unshift('All Games')
        return items
      },
      ITEMS() {
        var items =  this.tickets.reduce((res, item)=>{
            if(this.game != "All Games") {
              if(item.game == this.game) {
                res.push(item)
              }
            } else {
              res.push(item)
            }
            
            return res
        },[])
        return items
      },
      isOnline(){
        return this.$store.state.is_online
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      ticketEvent(){
        this.viewticket =false
      },
      setData() {
        var param = {}
        param.account = this.user.id
        param.method = "per_account"
        param.from_date = this.to_date
            this.$http.post("transaction/query", param).then(response => {
                response.data.transactions !=null?this.tickets = response.data.transactions:this.tickets=[]
               }).catch(e => {
                console.log(e.data)
            })
      }
    },
  }
</script>